import React from 'react'
import Layout from '../components/layout'

export default function muchAdoNews1() {
    return (
        <Layout>
            <div style={{padding: "108px 20px 20px 10px", width: 400, margin: "0 auto"}}>
                <h1>Messina Messenger</h1>

                <h2>Local private eye seeks source of damning picture of Mayor’s daughter </h2>

                <p>An investigation has launched into the person or persons responsible for the picture that appears to show Hero embracing an unidentified man behind the curtains of her bedroom window. 
Dogberry, who bills himself as “Messina’s best and only” private investigator, has been hired by the Mayor’s niece, Beatrice, to investigate what she deems an “assault” on the reputation of her cousin. 
Little has been heard or seen of Hero since the picture’s release by The Scandalous Crier two days ago. Many questions remain, including how the individuals were able to obtain such a view into the Mayor’s home. 
Dogberry requests immediate assistance from the citizens of Messina. “I am investigating all leads,” he asserts. “Send me any pacific information you have.” 
Please message leads to 315-306-3536. Dogberry will follow-up directly with anyone who can provide specific information. 
</p>
            </div>
        </Layout>
    )
}